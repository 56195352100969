<script>
import mysohoAPI from "@/api/external/mysoho.js";
import {setAuthToken} from "@/utils/auth.js";

export default {
  data() {
    return {};
  },
  mounted() {
    mysohoAPI
      .decrypt(this.$route.query.data, this.$route.query.date)
      .then(res => {
        if (res.status == 200) {
          setAuthToken(res.data.accessToken);

          switch (res.data.mode) {
            case "user_theme_list":
              this.$router.push("/admin/theme/" + res.data.target);
              break;

            case "user_theme_editor":
              this.$router.push("/editor/" + res.data.target);
              break;

            case "theme_list":
              this.$router.push(
                "/introduction/theme-list?id=3&userSolutionId=" +
                  res.data.target
              );
              break;

            case "theme_preview":
              this.$router.push(
                "/introduction/preview?id=" + res.data.target + "&fileName=main"
              );
              break;

            case "make_site":
              this.$router.push("/admin/solution");
              break;

            case "help_center":
              location.href = process.env.VUE_APP_EXTERNAL_qpick_help_URL;
              break;
          }
        } else {
          alert(res.data.errors[0].userMsg);
        }
      });
  }
};
</script>
